import React, { useEffect, useState} from 'react';
import { 
    GridList,
    GridListTile,
    ListSubheader,
    GridListTileBar,
    Snackbar,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import {SERVER_URL} from './Domian';
import axios from "axios";
import ImageUploader from 'react-images-upload';
import {UPLOAD_IMAGE_PATH} from './Config';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: "#e9e9e9",
    },
    gridList: {
      width: 500,
      height: 450,
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    iamge: {
        height: '100%',
        width: '100%',
        objectFit: 'contain'
    }
}));

const AdminSettingPage = () => {
    const classes = useStyles();
    const [images, setImages] = useState([]);

    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");

    const [newImageDialogOpen, setNewImageDialogOpen] = useState(false);
    const [newImage, setNewImage] = useState('');
    const [imageUploaderLabel, setImageUploaderLabel] = useState("Max file size: 3.5mb, accepted: jpg | png, Max. dimension 1000x1000");

    const fetchImages = async() => {
        const res = await axios.get(`${SERVER_URL}/api/image`);
        setImages(res.data);
    }

    useEffect(() => {
        fetchImages();
    }, []);

    const imageDelete = (name) =>{
        axios.delete(`${SERVER_URL}/api/image/${name}`).then(res => {
            setImages(res.data);
            setSnackBarMessage("Image Deleted");
            setSnackBarOpen(true);
        });
    }

    const handleSnackbarClose = (e, reason) => {
        if (reason === 'clickaway'){
            return;
        }
        setSnackBarOpen(false);
    }

    const onPictureDrop = (pic) => {
        setNewImage(pic);
    }

    const uploadImage = (e) => {

        if (newImage.length > 0){
            let formData = new FormData();

            formData.append("file", newImage[0]);

            axios.post(`${SERVER_URL}/api/image`, formData, {headers:{
                "Content-Type": "multipart/form-data",
            }}).then(res => {
                document.querySelector('.uploadPicturesWrapper .deleteImage').click();
                if (res.data === "saved"){
                    setImageUploaderLabel("Uploaded");
                }else{
                    setImageUploaderLabel("Upload failed");
                }
                fetchImages();
                axios.get(`${SERVER_URL}/api/image`).then(res => {
                    fetchImages();
                    setNewImageDialogOpen(false);
                    setSnackBarMessage("Image Uploaded.");
                    setSnackBarOpen(true);                 
                });
            });
        }else{
            setImageUploaderLabel("No file is chosen");
        }
    }

    return (
        <div>
            <IconButton size="small" aria-label="open" color="inherit" onClick={()=>setNewImageDialogOpen(true)}>
                <AddIcon fontSize="large" />
            </IconButton>
            <div className={classes.root}>
                <GridList cellHeight={180} className={classes.gridList}>          
                    <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
                        <ListSubheader component="div">Custom Image</ListSubheader>
                    </GridListTile>
                    {images.map((image, index) => (
                    <GridListTile key={index}>
                        <img src={`${UPLOAD_IMAGE_PATH}/${image}`} alt={image} className={classes.iamge}/>
                        <GridListTileBar
                        title={image}
                        actionIcon={
                            <IconButton aria-label={`Delete ${image}`} className={classes.icon} onClick={()=>imageDelete(image)}>
                                <DeleteIcon />
                            </IconButton>
                        }
                        />
                    </GridListTile>
                    ))}
                </GridList>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={snackBarOpen}
                    autoHideDuration={2000}
                    onClose={handleSnackbarClose}
                    message={snackBarMessage}
                    action={
                        <React.Fragment>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                />

                <Dialog
                    open={newImageDialogOpen}
                    onClose={() => setNewImageDialogOpen(false)}
                    aria-labelledby="new-image-title"
                    aria-describedby="new-image-description"
                >
                    <DialogTitle id="new-image-title">{`Add To New Image`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="new-image-description">
                        <ImageUploader
                            withIcon={true}
                            buttonText="Choose Images"
                            onChange={onPictureDrop}
                            imgExtension={['.jpg', '.gif', '.png']}
                            maxFileSize={3500000}
                            singleImage={true}
                            withPreview={true}
                            label={imageUploaderLabel}
                        />
                        </DialogContentText>
                        <DialogActions>
                            <Button onClick={() => setNewImageDialogOpen(false)} color="primary">
                                Close
                            </Button>
                            <Button onClick={uploadImage} color="primary" autoFocus>
                                Upload
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    );
}

export default AdminSettingPage;