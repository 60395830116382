import jsPDF from 'jspdf';
import JSZip from 'jszip';
import saveAs from 'file-saver';

export function downloadPNG(canvas, filename, callback) {
    
    var lnk = document.createElement('a'), e;

    lnk.download = `${filename}.png`;

    lnk.href = canvas.toDataURL("image/png;base64");

    if (document.createEvent) {
        e = document.createEvent("MouseEvents");
        e.initMouseEvent("click", true, true, window,
                        0, 0, 0, 0, 0, false, false, false,
                        false, 0, null);

        lnk.dispatchEvent(e);
    } else if (lnk.fireEvent) {
        lnk.fireEvent("onclick");
    }

    callback("PNG have been downloaded");
}
  
function generatePdfFile(canvas){
    var imgData = canvas.toDataURL("image/png", 1.0);
    var pdf = new jsPDF("l", "px", "a4");
    pdf.setLineWidth(1);
    
    var pdf_width = pdf.internal.pageSize.getWidth();
    var pdf_height = pdf.internal.pageSize.getHeight();
    var width = canvas.width;
    var height = canvas.height;
    var height_ratio = (pdf_height/height) * 0.98;
    var width_ratio = (pdf_width/width) * 0.98;

    if (height * width_ratio > pdf_height){
        width_ratio = height_ratio;
    }

    if (width * height_ratio > pdf_width){
        height_ratio = width_ratio;
    }

    pdf.addImage(imgData, 'PNG', (pdf_width - width*width_ratio)*0.5, (pdf_height - height*height_ratio)*0.5, width*width_ratio, height*height_ratio);
    pdf.rect((pdf_width - width*width_ratio)*0.5, (pdf_height - height*height_ratio)*0.5, width*width_ratio, height*height_ratio, 'S');
    return pdf;
}

export function downloadPDF(canvas, filename, callback) {
    generatePdfFile(canvas).save(`${filename}.pdf`);
    callback("PDF have been downloaded");
}

export function downloadAllPNG(canvasList, roomName, callback){
    var zip = new JSZip();

    canvasList.forEach((canvas, index)=>{
        const imgData = canvas.object.toDataURL();
        zip.file(`${canvas.name}.png`, imgData.split(',')[1], {base64: true});
    });
    zip.generateAsync({
        type: "blob",
        compression: "DEFLATE",
        compressionOptions: {
            level: 9
        }
    })
   .then(function (content) {

    console.info(content);

    saveAs(content, `${roomName}.zip`);
    callback("All PNG have been downloaded");
   });
}

export function downloadAllPDF(canvasList, roomName, callback){
    var zip = new JSZip();

    canvasList.forEach((canvas, index)=>{
        zip.file(`${canvas.name}.pdf`, generatePdfFile(canvas.object).output('blob'));
    });
    zip.generateAsync({
        type: "blob",
        compression: "DEFLATE",
        compressionOptions: {
            level: 9
        }
    })
   .then(function (content) {

    console.info(content);

    
    saveAs(content, `${roomName}.zip`);
    callback("All PDF have been downloaded");
   });
}