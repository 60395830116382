import { 
    Container,
    Button,
    Typography,
    AppBar,
    Tabs,
    Tab,
    Card,
    CardContent,
    Grid,
    CardActions,
    Box,
    IconButton,
    Snackbar,
    Backdrop,
    CircularProgress
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CachedIcon from '@material-ui/icons/Cached';
import React, { Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {SERVER_URL} from './Domian';
import axios from "axios";
import { fabric } from 'fabric';
import {downloadPNG, downloadPDF, downloadAllPNG, downloadAllPDF} from './Download';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import { Pagination } from '@material-ui/lab';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
            <div>
                {children}
            </div>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const a11yProps = (index) => ({
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
});

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        marginBottom: 30,
    },
    canvasInfo: {
        position: 'relative'
    },
    canvasDate: {
        position: 'absolute',
        right: 0,
        top: 0
    },
    buttonContainer: {
        margin: theme.spacing(1),
    },
    canvasContainer: {
        width: '50%',
    },
    refreshButton: {
        marginLeft: 'auto',
    },
    pagination: {
        marginTop: 30
    },
    backdrop:{
        zIndex: 1200,
        position: 'absolute'
    },
    card: {
        position: "relative"
    }
}));

const CanvasContainer = (props) => {

    const classes = useStyles();

    const canvas = props.canvas;
    const key = props.loginKey;

    const [isLoading, setIsLoading] = useState(false);

    return (
        <Grid item key={props.index} spacing={3} className={classes.canvasContainer}>
            <Card className={classes.card}>
                <CardContent>
                    <Box className={classes.canvasInfo}>
                        <Typography color="textSecondary" gutterBottom className={classes.canvasName}>
                            {canvas.name}
                        </Typography>
                        <Typography color="textSecondary" gutterBottom className={classes.canvasDate}>
                            Update At: {new Date(canvas.updateAt).toLocaleString('en-GB')}
                        </Typography>
                    </Box>
                    <Box className={classes.canvasViewPort} id={`canvas-${canvas.id}-container`}>
                        <Canvas id={`canvas-${canvas.id}`} canvas={canvas} setIsLoading={setIsLoading}/>
                    </Box>

                </CardContent>
                <CardActions>
                    <DownloadButton
                    onClick={props.handleDownloadPNG}
                    canvasID={canvas.id}
                    keyName={`${key.description}-${canvas.name}-${canvas.updateAt}`}
                    >
                        Download as PNG
                    </DownloadButton>

                    <DownloadButton
                    onClick={props.handleDownloadPDF}
                    canvasID={canvas.id}
                    keyName={`${key.description}-${canvas.name}-${canvas.updateAt}`}
                    >
                        Download as PDF
                    </DownloadButton>
                </CardActions>
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </Card>
        </Grid>
    );
}

const Canvas = (props) => {

    const id = props.id;
    const canvasObj = props.canvas;
    const setIsLoading = props.setIsLoading;

    const [canvas, setCanvas] = useState('');

    useEffect(() => {
        setCanvas(initCanvas());
    }, []);

    const initCanvas = () => {
        setIsLoading(true);
        const canvasData = JSON.parse(canvasObj.url);
        var container = document.getElementById(`${id}-container`).parentNode;
        var style = getComputedStyle(container);
        var containerWidth = container.offsetWidth - parseInt(style.paddingLeft) - parseInt(style.paddingRight);
        var original_height = canvasData.height || 1664;
        var original_width = canvasData.width || 2402;
        var scaleRatio = containerWidth/original_width;
        
        let c = new fabric.Canvas(id, {
            height: original_height,
            width: original_width,
            selection: false, 
        });
        //c.setZoom(scaleRatio);

        c.loadFromJSON(canvasData, () => {
            c.forEachObject((obj) => {
                obj.selectable = false;
            });
            c.renderAll();
            setIsLoading(false);
        });
        document.getElementById(`${id}-container`).style.transform = `scale(${scaleRatio})`;
        document.getElementById(`${id}-container`).style.transformOrigin = `0 0`;
        document.getElementById(`${id}-container`).style.height = `${original_height*scaleRatio + 50}px`;
        document.getElementById(`${id}-container`).parentNode.style.height = `${original_height*scaleRatio + 20}px`;
        return c;
    }


    return(
        <Fragment>
            <canvas style={{ border: 'solid 1px #555' }} id={id} />
        </Fragment>
    );
}

const DownloadAllButton = (props) => {
    const classes = useStyles();

    const {onClick, canvasGroupByKey, keyName, children} = props;

    const handleClick = () => {
        onClick(canvasGroupByKey.map((canvas)=>({object: document.getElementById(`canvas-${canvas.id}`), name: canvas.name})), keyName)
    }


    return (
        <Button  className={classes.buttonContainer} variant="contained" size="small" color="primary"
        onClick={handleClick} endIcon={<SystemUpdateAltOutlinedIcon />}
        >
            {children}
        </Button>

    );
}

const DownloadButton = (props) => {
    const classes = useStyles();

    const {onClick, canvasID, keyName, children} = props;

    const handleClick = () => {
        onClick(canvasID, keyName)
    }

    return (
        <Button size="small" color="primary"
        onClick={handleClick} endIcon={<GetAppOutlinedIcon />}
        >
            {children}
        </Button>

    );
}


const AdminCanvasPage = () => {
    const classes = useStyles();

    const [keys, setKeys] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [canvasList, setCanvasList] = useState({});

    const [page, setPage] = useState(0);
    const [totalPage, setTotalPage] = useState(1);

    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");

    const [fetching, isFetching] = useState(false);

    const fetchKeys = async () => {
        const res = await axios.get(`${SERVER_URL}/api/key`);
        setKeys(res.data);
        fetchCanvasList(res.data[tabValue].value); 
        return res.data;
    };

    const fetchCanvasList = async (value) => {
        isFetching(true);
        const res = await axios.get(`${SERVER_URL}/api/canvas/${value}?page=${page}&size=10`);
        setCanvasList({});
        let c_list = {};
        c_list[value] = res.data.content;
        setCanvasList(c_list);
        setTotalPage(res.data.totalPages);
        isFetching(false);
    }

    const refreshCanvasList = () => {
        fetchCanvasList(keys[tabValue].value);
    }

    const handleChange = (event, value) => {
        setPage(value - 1);
    };

    const handleSnackbarClose = (e, reason) => {
        if (reason === 'clickaway'){
            return;
        }
        setSnackBarOpen(false);
    }

    const handleDownloadAllPNG = (canvasList, roomName) => {
        setSnackBarMessage("Loading");
        setSnackBarOpen(true);
        downloadAllPNG(canvasList, roomName, callbackForDownload);
    }

    const handleDownloadAllPDF = (canvasList, roomName) => {
        setSnackBarMessage("Loading");
        setSnackBarOpen(true);
        downloadAllPDF(canvasList, roomName, callbackForDownload);   
    }

    const handleDownloadPNG = (canvasID, rooName) => {
        setSnackBarMessage("Loading");
        setSnackBarOpen(true);
        downloadPNG(document.getElementById(`canvas-${canvasID}`), rooName, callbackForDownload);
    }

    const handleDownloadPDF = (canvasID, rooName) => {
        setSnackBarMessage("Loading");
        setSnackBarOpen(true);
        downloadPDF(document.getElementById(`canvas-${canvasID}`), rooName, callbackForDownload);
    }

    const callbackForDownload = (msg) => {
        setSnackBarOpen(false);
        setSnackBarMessage(msg);
        setSnackBarOpen(true);
    }

    useEffect(() => {
        fetchKeys();  
    }, [tabValue, page]);

    

    return (
        <Container>
            <AppBar position="static" color="default" className={classes.appBar}>
                <Tabs
                value={tabValue}
                onChange={(e, newValue) => {
                    setTabValue(newValue);
                }}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                >
                {(keys || []).map((key, index) =>(
                        <Tab 
                        key = {index}
                        label={key.description}
                        {...a11yProps(index)}
                        />
                    )
                )}
                </Tabs>
            </AppBar>

            {(keys || []).map((key, index) =>(
                    <TabPanel value = {tabValue} index={index} key={index}>
                        {totalPage ? <Typography>Page: {page + 1}</Typography> : ""}
                        <Box>
                            <DownloadAllButton
                            onClick = {handleDownloadAllPNG}
                            canvasGroupByKey = {(canvasList[key.value] || [])}
                            keyName = {key.description}
                            >
                                Download All as PNG
                            </DownloadAllButton>

                            <DownloadAllButton
                            onClick = {handleDownloadAllPDF}
                            canvasGroupByKey = {(canvasList[key.value] || [])}
                            keyName = {key.description}
                            >
                                Download All as PDF
                            </DownloadAllButton>
                            <IconButton className={classes.refreshButton} onClick={refreshCanvasList} disabled={fetching}>
                                <CachedIcon/>
                            </IconButton>
                        </Box>
                        <Grid container spacing={3}>
                        {
                            (canvasList[key.value] || []).map((canvas, index) => <CanvasContainer index={index} canvas={canvas} loginKey={key} handleDownloadPNG={handleDownloadPNG} handleDownloadPDF={handleDownloadPDF}/>)
                        }
                        </Grid>
                    </TabPanel>
                )
            )}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={snackBarOpen}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
                message={snackBarMessage}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
            <div className={classes.pagination}>{totalPage ? <Pagination count={totalPage} shape="rounded" onChange={handleChange}/> : ""}</div>

        </Container>
    );
}

export default AdminCanvasPage;