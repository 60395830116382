import { List, ListItem, Paper, withStyles  } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import React from 'react';
import { useTranslation } from 'react-i18next';

const styles = theme => ({
    root: {
        position: 'relative',
        zIndex: 999,
        cursor: 'pointer',
        '&:hover' : {
            "& $paperRoot": {
                display: 'block',
                position: 'absolute'
            }
        }
    },
    paperRoot: () => ({
        display: 'none',
    })
});

const SwitchLanguageButton = ({classes}) => {

    const {i18n} = useTranslation();

    return (
        <div className={classes.root}>
            <LanguageIcon/>
            <Paper className={classes.paperRoot} elevation={3}>
                <List>
                    <ListItem disablePadding button onClick={() => i18n.changeLanguage("en")}>
                        English
                    </ListItem>
                    <ListItem disablePadding button onClick={() => i18n.changeLanguage("zh")}>
                        中文
                    </ListItem>
                </List>
            </Paper>
        </div>
    );
}

export default withStyles(styles,{ withTheme: true})(SwitchLanguageButton);