import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const avaliableLanguages = ['en', 'zh'];

i18n.use(Backend)
.use(LanguageDetector)
.use(initReactI18next)
.init({
    fallbackLng: "en",
    detection: {
        checkWhitelist: true,
    },
    debug: false,
    whitelist: avaliableLanguages,
    interpolation: {
        escapeValue: false // no need for react. it escapes by default
    },
});


export default i18n;
