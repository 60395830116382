import React, { memo, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
    TextField,
    FormControlLabel,
    Checkbox,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Container,
    Snackbar,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import axios from "axios";
import {SERVER_URL} from './Domian';
import {makeid} from './IdGenerator';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import moment from 'moment/moment';
import QRCode from "react-qr-code";

const useStyles = makeStyles((theme) => ({
    login_key_page: {
        display: 'flex',            
        justifyContent: 'center',
    },
    input_wrapper:{
        display: 'flex',
        flexDirection: 'column',
        '& label.Mui-focused': {
            color: '#006699',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#006699',
        },
    },
    table: {
        minWidth: 650,
    },
    searchField: {
        width: '100%' ,
        '& label.Mui-focused': {
            color: '#006699',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#006699',
        },
    },
    copy: {
        backgroundColor: "#006699",
        color: "white"
    },
    delete: {
        backgroundColor: "#e6005c",
        color: "white"
    }
}));

const KeyTable = memo(({
    displayKeys,
    setSnackBarMessage,
    setSnackBarOpen,
    setKeyToBeDelete,
    setAlertDialogOpen
}) => {
    const classes = useStyles();

        const ExportQRCode = (value) => {
        const qrcode = document.getElementById(`qr-code-${value}`).outerHTML;
        // let blob = new Blob([qrcode], {type : 'image/svg'});
        // let downloadLink = document.createElement("a");
        // downloadLink.href = URL.createObjectURL(blob, 'svg');
        // downloadLink.download = `${value}.svg`;
        // document.body.appendChild(downloadLink);
        // downloadLink.click();
        // document.body.removeChild(downloadLink);

        var canvas = document.createElement("canvas");

        var context = canvas.getContext("2d");

        canvas.width = 512;
        canvas.height = 512;

        let image = new Image(); // create <img> element
        image.onload = function () {
            // define fill (specify 'no-repeat' if you don't want it to repeat
            context.fillStyle = context.createPattern(image, 'repeat'); 
            // fill rectangle with defined fill
            context.fillRect(0, 0, canvas.width, canvas.height); 
            var a = document.createElement('a');

            a.download = `${value}.png`;;
            a.href = canvas.toDataURL("image/png");

            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        };

        image.src = 'data:image/svg+xml;base64,' + btoa(qrcode); 
    }

    const CopyKey = (value) => {
        var textArea = document.createElement("textarea");
        textArea.value = value;
        document.body.appendChild(textArea);
        if (navigator.userAgent.match(/ipad|iphone/i)){
            var range = document.createRange();
            range.selectNodeContents(textArea);
            var selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
        }else{
            textArea.select();
        }
        try {
            var successful = document.execCommand('copy');
            if(successful)
            {
                setSnackBarMessage("Key Copied.");
                setSnackBarOpen(true);        
            }else{
                setSnackBarMessage('Oops, unable to copy');
                setSnackBarOpen(true);
            }
        } 
        catch (err) 
        {
            setSnackBarMessage('Oops, unable to copy2');
            setSnackBarOpen(true);
        }

        textArea.remove();
    }

    return (
        <TableContainer component={Paper}>
                    <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Room Name</TableCell>
                            <TableCell align="right">Keys</TableCell>
                            <TableCell align="right">Advance Mode</TableCell>
                            <TableCell align="right">Expiry Date</TableCell>
                            <TableCell align="right">Dynamic Expiration(?)</TableCell>
                            <TableCell align="right">Activation Date</TableCell>
                            <TableCell align="right">Day to expire</TableCell>
                            <TableCell align="right">Export in QR code format</TableCell>
                            <TableCell align="right">Copy Key</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayKeys.map((key) => (
                            <TableRow key={key.description}>
                                <TableCell component="th" scope="row">
                                    {key.description}
                                </TableCell>
                                <TableCell align="right">{key.value}</TableCell>
                                <TableCell align="right">{key.simple ? "No" : "Yes"}</TableCell>
                                <TableCell align="right">{key.dynamic ? (key.activeAt ? moment(key.activeAt).add(key.dayToExpire, 'd').format("YYYY-MM-DD") : "N/A") : key.expireAt}</TableCell>
                                <TableCell align="right">{key.dynamic ? "Yes" : "No"}</TableCell>
                                <TableCell align="right">{key.activeAt}</TableCell>
                                <TableCell align="right">{key.dynamic ? key.dayToExpire : ""}</TableCell>
                                <TableCell align="right">
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%", display: 'none'}}>
                                            <QRCode
                                                id={`qr-code-${key.value}`}
                                                size={256}
                                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                value={key.value}
                                                level="H"
                                                viewBox={`0 0 256 256`}
                                            />
                                        </div>
                                        <Button variant="contained" 
                                                onClick={() => ExportQRCode(key.value)} 
                                                startIcon={<FileCopyOutlinedIcon />}
                                                className={classes.copy}
                                        >
                                            Export
                                        </Button>
                                    </Box>
                                </TableCell>
                                <TableCell align="right">
                                    <Button variant="contained" 
                                            onClick={() => CopyKey(key.value)} 
                                            startIcon={<FileCopyOutlinedIcon />}
                                            className={classes.copy}
                                    >
                                        Copy
                                    </Button>
                                </TableCell>
                                <TableCell align="right">
                                    <Button 
                                        variant="contained" 
                                        onClick={() => {
                                            setKeyToBeDelete(key);
                                            setAlertDialogOpen(true);
                                        }}
                                        startIcon={<DeleteOutlineOutlinedIcon />}
                                        className={classes.delete}
                                    >
                                        Delete
                                </Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    </Table>
                </TableContainer>
    );
}); 



const AdminKeyPage = () => {
    const classes = useStyles();
    const [keys, setKeys] = useState([]);

    const [displayKeys, setDisplayKeys] = useState([]);
    const [searchWord, setSearchWord] = useState("");

    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");

    const [alertDialogOpen, setAlertDialogOpen] = useState(false);
    const [keyToBeDelete, setKeyToBeDelete] = useState({
        value: "",
        description: "",
        simple: true,
        expireAt: new Date()
    });

    const [newKeyDialogOpen, setNewKeyDialogOpen] = useState(false);
    const [newKey, setNewKey] = useState({
        description: "",
        simple: true,
        expireAt: new Date()
    });

    const fetchKeys = async () => {
        const res = await axios.get(`${SERVER_URL}/api/key`);
        
        setKeys(res.data);
        setDisplayKeys(res.data);
    };

    const saveKeys = async (newKey) => {
        await axios.put(`${SERVER_URL}/api/key`, newKey);
    }

    const deleteKey = async (value) => {
        await axios.delete(`${SERVER_URL}/api/key/${value}`);
    }

    useEffect(() => {
        fetchKeys();
    }, []);

    const GenerateKey = async () => {
        var value = makeid(16);
        while (keys.includes(value)){
            value = makeid(16);
        }

        await saveKeys({
            value: value,
            ...newKey
        });
        setNewKey({
            description: "",
            simple: true,
            expireAt: new Date()
        });
        fetchKeys();
        setNewKeyDialogOpen(false);
        setSnackBarMessage("Key Generated.");
        setSnackBarOpen(true);
    }

    const DeleteKey = async (value) => {
        await deleteKey(value);
        fetchKeys();
        setAlertDialogOpen(false);
        setSnackBarMessage("Key Deleted.");
        setSnackBarOpen(true); 
    }

    const handleSnackbarClose = (e, reason) => {
        if (reason === 'clickaway'){
            return;
        }
        setSnackBarOpen(false);
    }

    const handleAlertDialogClose = () => {
        setAlertDialogOpen(false);
    }

    return (
        <div className={classes.login_key_page}>
            <Container maxWidth="xl">
                
                <IconButton size="small" aria-label="open" color="inherit" onClick={()=>setNewKeyDialogOpen(true)}>
                    <AddIcon fontSize="large" />
                </IconButton>
                <TextField
                    className={classes.searchField} 
                    value={searchWord}
                    onChange={(e) => {
                        setSearchWord(e.target.value);
                        setDisplayKeys(keys.filter((key) => {
                            return key.description.includes(e.target.value) || key.value.includes(e.target.value)
                        }));
                    }}
                    label="Search By Room Name / Key"
                    variant="filled"
                />
                <KeyTable
                    displayKeys={displayKeys}
                    setSnackBarMessage={setSnackBarMessage}
                    setSnackBarOpen={setSnackBarOpen}
                    setKeyToBeDelete={setKeyToBeDelete}
                    setAlertDialogOpen={setAlertDialogOpen}
                />
                
            </Container>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={snackBarOpen}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
                message={snackBarMessage}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />

            <Dialog
                open={alertDialogOpen}
                onClose={handleAlertDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Confirm To Delete Key (${keyToBeDelete.description})?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        If you delete the key, all information (including all the canvas) will be deleted.
                    </DialogContentText>
                    <DialogActions>
                        <Button onClick={handleAlertDialogClose} color="primary">
                            Don't Delete
                        </Button>
                        <Button onClick={() => DeleteKey(keyToBeDelete.value)} color="primary" autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>

            <Dialog
                open={newKeyDialogOpen}
                onClose={() => setNewKeyDialogOpen(false)}
                aria-labelledby="new-dialog-title"
                aria-describedby="new-dialog-description"
            >
                <DialogTitle id="new-dialog-title">Generate New Room</DialogTitle>
                <DialogContent>
                    <DialogContentText id="new-dialog-description">
                    <div className={classes.input_wrapper}>
                        <TextField 
                            value={newKey.description}
                            onChange={(e) => {
                                setNewKey({
                                    ...newKey,
                                    description: e.target.value
                                })
                            }}
                            label="Input the Room Name"
                        />
                        <div>
                            <FormControlLabel
                                control={<Checkbox 
                                    checked={!newKey.simple}
                                    onChange={(e) => {
                                        setNewKey({
                                            ...newKey,
                                            simple: !e.target.checked
                                        })
                                    }}
                                    inputProps={{ 'aria-label': 'Enable Advance Versrion?' }}
                                />}
                                label = "Enable Advance Version?"
                            />
                            <FormControlLabel
                                control={<Checkbox 
                                    checked={newKey.dynamic}
                                    onChange={(e) => {
                                        setNewKey({
                                            ...newKey,
                                            dynamic: e.target.checked
                                        })
                                    }}
                                    inputProps={{ 'aria-label': 'Enable Dynamic Expiration?' }}
                                />}
                                label = "Enable Dynamic Expiration?"
                            />
                            {
                                !newKey.dynamic ? <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="date-picker-inline"
                                        label="Expiry Date"
                                        value={newKey.expireAt}
                                        onChange={(date) => {
                                            setNewKey({
                                                ...newKey,
                                                expireAt: date
                                            })
                                        }}
                                        KeyboardButtonProps={{
                                        'aria-label': 'Expiry Date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                                :
                                <TextField 
                                    value={newKey.dayToExpire}
                                    onChange={(e) => {
                                        setNewKey({
                                            ...newKey,
                                            dayToExpire: e.target.value
                                        })
                                    }}
                                    label="Day(s) To Expire"
                                    type="number"
                                />
                            }
                        </div>
                    </div>
                    </DialogContentText>
                    <DialogActions>
                        <Button onClick={() => setNewKeyDialogOpen(false)} color="primary">
                            Close
                        </Button>
                        <Button onClick={GenerateKey} color="primary" autoFocus>
                            Generate
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default AdminKeyPage;