import React, { Suspense } from 'react';
import CanvasPage from './CanvasPage';
import Login from './Login';
import AdminLogin from './AdminLogin';
import AdminDashboard from './AdminDashboard';
import UserRoute from './UserRoute';
import AdminRoute from './AdminRoute';
import interceptors from './Interceptors';
import PropTypes from "prop-types";
import axios from "axios";
import {SERVER_URL} from './Domian';
import './App.css';
import {
  Route,
  withRouter
} from "react-router-dom";
import { MuiThemeProvider } from '@material-ui/core/styles';
import './i18n/i18n';


class App extends React.Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
        login_key: "",
        admin_username:"",
        admin_password:"",
        auth: false,
        admin_auth: false,
        token: "",
        login_error: ""
    }
  }

  componentWillMount(){
    if (localStorage.getItem("authorization")){
      if (localStorage.getItem("authorization_role") === "user"){
        this.setState({
          auth: true,
          token: localStorage.getItem("authorization"),
          login_key: localStorage.getItem("login_key")
        });
      }else{
        this.setState({
          auth: true,
          admin_auth: true,
          token: localStorage.getItem("authorization")
        });
      }
    }
  }

  authenticate = (event) => {
    event.preventDefault();
    const endpoint = `${SERVER_URL}/authenticate/key`;

    const key_object = {
      value: this.state.login_key
    };
    axios.post(endpoint, key_object).then(res => {
      localStorage.setItem("authorization", res.data.token);
      localStorage.setItem("authorization_role", "user");
      localStorage.setItem("login_key", this.state.login_key)
      this.setState(
        { 
          auth: true,
          token: res.data.token
        }
      );
      return this.routeTo("/canvas");
    }, err => {
      if (err.response.data.message == "Key Expired"){
        this.setState(
          { 
            login_error: "This Key is expired"
          }
        );
      }else{
        this.setState(
          { 
            login_error: "Wrong Login Key"
          }
        );
      }
    });
  }

  admin_authenticate = (event) => {
    event.preventDefault();
    const endpoint = `${SERVER_URL}/authenticate`;

    const account_object = {
      username: this.state.admin_username,
      password: this.state.admin_password
    };
    axios.post(endpoint, account_object).then(res => {
      localStorage.setItem("authorization", res.data.token);
      localStorage.setItem("authorization_role", "admin");
      this.setState(
        { 
          auth: true,
          admin_auth: true,
          token: res.data.token
        }
      );
      return this.routeTo("/admin");
    }, err => {
      this.setState(
        { 
          login_error: "Wrong Username or Password"
        }
      );
    });
  }

  routeTo = (path)=>{
    axios.get(`${SERVER_URL}/api/check${path}`).then(res => {
      if (res.data === "success") {
        this.props.history.push(`${path}/`);
      } else {
        alert("Authentication failure");
      }
    });
  }

  clearToken = () => {
    localStorage.clear();
    this.setState(
      { 
        auth: false,
        admin_auth:false,
        token: ""
      }
    );
    this.props.history.push("/");
  }

  keyChange = e => {
    this.setState({login_key: e.target.value});
  }

  admin_usernameChange = e => {
    this.setState({admin_username: e.target.value});
  }

  admin_passwordChange = e => {
    this.setState({admin_password: e.target.value});
  }

  isAuthed=()=>{
    return this.state.auth;
  }

  isAdminAuthed = () =>{
    return this.state.admin_auth;
  }

  render() {
    return (
      <Suspense fallback={null}>
          <MuiThemeProvider>
            <div className="App">
                <Route exact path={["/", "/login"]} 
                  render={(props) => (
                    <Login {...props} 
                      authenticate={this.authenticate}
                      keyChange = {this.keyChange}
                      logout = {this.clearToken}
                      login_error = {this.state.login_error}
                      login_key = {this.state.login_key}
                    />
                  )}
                />
                <Route exact path={["/login/admin"]} 
                  render={(props) => (
                    <AdminLogin {...props} 
                      authenticate={this.admin_authenticate}
                      usernameChange = {this.admin_usernameChange}
                      passwordChange = {this.admin_passwordChange}
                      logout = {this.clearToken}
                      login_error = {this.state.login_error}
                      username = {this.state.admin_username}
                      password = {this.state.admin_password}
                    />
                  )}
                />
                <UserRoute path="/canvas" component={CanvasPage} isAuthed = {this.isAuthed} login_key={this.state.login_key}/>
                <AdminRoute path="/admin" component={AdminDashboard} isAuthed = {this.isAdminAuthed}/>
              </div>
          </MuiThemeProvider>
      </Suspense>
    );
  }
}

const AppWithRouter = withRouter(App)

export default AppWithRouter;
