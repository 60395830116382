import React from 'react';
import './App.css';
import { withStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import logo from './canvas_image/Mushroom-X Logo FINAL (Colour_CMYK).png';
import Image from './canvas_image/bg_white.png';
import LoginImage from './canvas_image/bg_login.png';
import { useTranslation } from 'react-i18next';
import SwitchLanguageButton from './SwitchLanguageButton';

const styles = theme => ({
  programName:{
    fontFamily: "ByomBoldTrial",
    textShadowColor: 'white',
    textShadowRadius: 10,
    fontSize: "44px",
    '&:first-letter':{
      fontSize: "54px"
    }
  },
  programName2:{
    fontFamily: "ByomBoldTrial",
    textShadowColor: 'white',
    textShadowRadius: 10,
    color: "#006699"
  },
  title:{
    fontFamily: "MyriadProBold",
    textShadowColor: 'white',
    textShadowRadius: 10
  },
  paperContainer: {
    height: "100%",
    backgroundImage: `url(${Image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: "#000000",
    overflowY: "auto",
  },
  paper: {/*
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(5),
    },*/
    backgroundImage: `url(${LoginImage})`,
    backgroundSize: 'cover',
    position: 'relative'
  },
  layout: {
    position: 'absolute', 
    left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  root:{
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    //marginTop: theme.spacing(1),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(5),
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: "#006699",
      },
      '&:hover fieldset': {
        borderColor: '#006699',
      },
    },
    borderRadius: 3
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:"#006699",
    '&:hover': {
      background: "#BECD70",
    },
    fontFamily: "MyriadProBold"
  },
  logo: {
    maxWidth: '100px'
  },
  helperText:{
    color: 'red'
  },
  textField: {
    fontFamily: "MyriadProBold",
    '& label.Mui-focused': {
      color: '#006699',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
      },
      '&:hover fieldset': {
      },
      '&.Mui-focused fieldset': {
        borderColor: '#006699',
      },
    },
  },
  langSwitch: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 10
  }
});

const Login = (props) => {

  const { classes, authenticate } = props;
  const {t} = useTranslation();

  return (
    <div className={classes.paperContainer}>
    <Container className={classes.layout}>
      <CssBaseline />
      <Typography component="h1" variant="h2"  align="center" className={classes.programName}>
        x-Spore
      </Typography>
      <Typography component="h1" variant="h5"  align="center" className={classes.programName2}>
        The Spore Print Art Program
      </Typography>
      <Paper className={classes.paper} elevation={3}>
          <div className={classes.langSwitch}>
            <SwitchLanguageButton/>
          </div>
          <form className={classes.form} onSubmit={authenticate}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography component="h1" variant="h5"  align="center" className={classes.title}>
                {t('user_login_page.user_login')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField 
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  type="password"
                  label={t('user_login_page.login_key')}
                  value={props.login_key}
                  onChange={props.keyChange}
                  helperText={props.login_error }
                  className={classes.textField}
                  FormHelperTextProps={{
                    className: classes.helperText
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button 
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit} 
                  >
                  {t('user_login_page.login')}
                </Button>
              </Grid>
            </Grid>
          </form>
      </Paper>
      <Box mt={3}>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justify="center"
      >
        <img src={logo} alt="mushroomX-logo" align="center" className={classes.logo}/> 
      </Grid>
      <Typography variant="body2" align="center" className={classes.title}>
        Copyright ©
        {new Date().getFullYear()}
        {'.'}
      </Typography>
      </Box>
    </Container>
    
    </div>
  );
}

export default withStyles(styles,{ withTheme: true})(Login);