import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import logo from './canvas_image/Mushroom-X Logo FINAL (White_CMYK).png';
import Image from './canvas_image/bg_black.png';


const styles = theme => ({
  programName:{
    fontFamily: "ByomBoldTrial",
    textShadowColor: 'white',
    textShadowRadius: 10,
    color: "#fff",
    fontSize: "44px",
    '&:first-letter':{
      fontSize: "54px"
    }
  },
  programName2:{
    fontFamily: "ByomBoldTrial",
    textShadowColor: 'white',
    textShadowRadius: 10,
    color: "#fff"
  },
  title:{
    color: "white",
    fontFamily: "MyriadProBold"
  },
  formTitle:{
    fontFamily: "MyriadProBold"
  },
  paperContainer: {
    height: "100%",
    backgroundImage: `url(${Image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: "#000000",
    overflowY: 'auto'
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(5),
    },
  },
  layout: {
    position: 'absolute', 
    left: '50%', 
    top: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  textField: {
    fontFamily: "MyriadProBold",
    '& label.Mui-focused': {
      color: '#006699',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
      },
      '&:hover fieldset': {
      },
      '&.Mui-focused fieldset': {
        borderColor: '#006699',
      },
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    fontFamily: "MyriadProBold",
    margin: theme.spacing(3, 0, 2),
    backgroundColor:"#006699",
    '&:hover': {
      background: "#BECD70",
    }
  },
  logo: {
    maxWidth: '100px'
  },
  helperText:{
    color: 'red'
  },
});

class AdminLogin extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.paperContainer}>
      <Container className={classes.layout}>
        <CssBaseline />
        <Typography component="h1" variant="h2"  align="center" className={classes.programName}>
          x-Spore
        </Typography>
        <Typography component="h1" variant="h5"  align="center" className={classes.programName2}>
          The Spore Print Art Program
        </Typography>
        <Paper className={classes.paper} >
            <form className={classes.form} onSubmit={this.props.authenticate}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography component="h1" variant="h5"  align="center" className={classes.formTitle}>
                  Admin Login
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth 
                    type="text"
                    label="Admin User Name"
                    value={this.props.username}
                    onChange={this.props.usernameChange}
                    className={classes.textField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth 
                    type="password"
                    label="Admin Password"
                    value={this.props.password}
                    onChange={this.props.passwordChange}
                    helperText={this.props.login_error}
                    className={classes.textField}
                    FormHelperTextProps={{
                      className: classes.helperText
                    }}
                  />
                </Grid>
              <Button 
                className={classes.submit} 
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Login
              </Button>
              </Grid>
            </form>
        </Paper>
        <Box>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <img src={logo} alt="mushroomX-logo" align="center" className={classes.logo}/> 
          </Grid>
          <Typography variant="body2" align="center" className={classes.title}>
            Copyright ©
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        </Box>
      </Container>
      </div>
    );
  }
}

export default withStyles(styles,{ withTheme: true}) (AdminLogin);