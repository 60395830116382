import axios from "axios";

axios.interceptors.request.use(
    function(config){
        const jwtToken = localStorage.getItem("authorization");
        if (jwtToken) {
            config.headers["authorization"] = "Bearer " + jwtToken;
        }
        return config;
    },function(err) {
        return Promise.reject(err);
    }
);

axios.interceptors.response.use(
    function(res){
        return res;
    },function(err) {
        return Promise.reject(err);
    }
);