import React from 'react';
import {
    Route,
    Redirect
} from "react-router-dom";




class UserRoute extends React.Component {

    constructor(props) {
        super(props);
    }

    render(){
        const { component: Component, login_key: key, ...rest } = this.props;
        return (
            <Route
            {...rest}
            render={props => this.props.isAuthed()
                ? (<Component {...props} login_key={key}/>)
                : (<Redirect to={{pathname: '/login', state: {from: props.location}}} />)}
            />
        )
    }
}

export default UserRoute;