import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
    AppBar,
    Toolbar,
    List,
    ListItem,
    ListItemText,
    Drawer,
    Button,
} from '@material-ui/core';
import logo from './canvas_image/Mushroom-X Logo FINAL (White_CMYK).png';
import AdminKeyPage from './AdminKeyPage';
import AdminCanvasPage from './AdminCanvasPage';
import AdminSettingPage from './AdminSettingPage';
import { Link, Route, Switch } from 'react-router-dom';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import PhotoSizeSelectActualOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActualOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root:{
        display: 'flex'
    },
    topBar:{
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        backgroundColor: "#006699"
    },
    drawer:{
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        maxWidth: `calc(100% - ${drawerWidth}px)`,
        overflowY: 'scroll',
        height: '100vh'
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
    span:{
        flexGrow: 1,
    },
    logo: {
        maxWidth: '100px'
    }
}));


const AdminDashboard = () => {
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(true);

    const Logout = () => {
        localStorage.clear();
        window.location.href = "/login/admin";
    }

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.topBar}>
                <Toolbar>
                    <img src={logo} alt="mushroomX-logo" className={classes.logo}/>
                    <div className={classes.span}></div>
                    <Button color="inherit" onClick={Logout} endIcon={<ExitToAppOutlinedIcon />}>Logout</Button>
                </Toolbar>
            </AppBar>
            <Drawer 
                open={true}
                variant="permanent"
                anchor="left"
                className={classes.drawer}
                classes={{
                    paper: classes.drawerPaper
                }}
            >
                <List>
                    <Link to='/admin/key' className={classes.link}>
                        <ListItem button key={'Login Key'}>
                            <ListItemIcon>
                                <VpnKeyOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Login Key'}/>
                        </ListItem>
                    </Link>
                    <Link to='/admin/canvas' className={classes.link}>
                        <ListItem button key={'Canvas'}>
                            <ListItemIcon>
                                <PhotoSizeSelectActualOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Canvas'}/>
                        </ListItem>
                    </Link>
                    <Link to='/admin/settings' className={classes.link}>
                        <ListItem button key={'Settings'}>
                            <ListItemIcon>
                                <SettingsOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Settings'}/>
                        </ListItem>
                    </Link>
                </List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar}/>
                <Switch>
                    <Route exact path={['/admin' ,'/admin/key']} render={props => <AdminKeyPage {...props}/>}/>
                    <Route exact path='/admin/canvas' render={props => <AdminCanvasPage {...props}/>}/>
                    <Route exact path='/admin/settings' render={props => <AdminSettingPage {...props}/>}/>
                </Switch>
                <div className={classes.toolbar}/>
            </main>
        </div>
    );
}

export default AdminDashboard;